<template>
  <Popup ref="popupRef" width="500px" title="商家信息" :showConfirm="false" :showCancel="false">
    <div class="detail" v-if="merchantInfo">
      <div class="ent-row">
        <div class="label">ID：</div>
        <span>{{mid}}</span>
      </div>
      <div class="ent-row">
        <div class="label">商家名称：</div>
        <span>{{merchantInfo.name || ''}}</span>
      </div>
      <div class="ent-row">
        <div class="label">手机号：</div>
        <span>{{merchantInfo.phone || ''}}</span>
      </div>
      <div class="ent-row">
        <div class="label">报名单余额：</div>
        <span>{{merchantInfo.enrollFormBalance || ''}}</span>
      </div>
      <div class="ent-row">
        <div class="label">点券余额：</div>
        <span>{{merchantInfo.ticketBalance || ''}}</span>
      </div>
      <div class="ent-row">
        <div class="label">保证金余额：</div>
        <span>{{merchantInfo.bailBalance || ''}}</span>
      </div>
      <div class="ent-row">
        <div class="label">认证类型：</div>
        <span>{{identityTypes[merchantInfo.identityType] || ''}}</span>
      </div>
      <div class="ent-row">
        <div class="label">会员类型：</div>
        <span>{{vipTypes[merchantInfo.vipType] || ''}}</span>
      </div>
      <div v-if="merchantInfo.identityType == 2">
        <div class="ent-row">
          <div class="label">企业ID：</div>
          <span>{{merchantInfo.entId}}</span>
        </div>
        <div class="ent-row">
          <div class="label">企业名称：</div>
          <span>{{merchantInfo.entName}}</span>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { merchantInfoApi } from '@/api/admin.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      mid: '',
      merchantInfo: null,
      identityTypes: {
        0: '未认证',
        1: '个人认证',
        2: '企业认证',
        3: '个人认证审核中',
        4: '企业认证审核中',
        5: '个人认证审核已驳回',
        6: '企业认证审核已驳回'
      },
      vipTypes: {
        0: '普通会员',
        1: '青铜会员',
        2:'白银会员',
        3: '黄金会员',
        4: '会员已过期'
      }
    }
  },
  methods: {
    open(option) {
      this.mid = option.mid || ''
      merchantInfoApi({
        mid: this.mid
      }).then(res => {
        console.log('商家信息', res)
        this.merchantInfo = res.data
        this.$refs.popupRef.open()
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
      
    },
    close() {
      this.$refs.popupRef.close()
    },
    confirm() {

    },
    resetForm() {

    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  line-height: 30px;
  .ent-row {
    display: flex;
    .label {
      width: 100px;
      text-align: right;
    }
    span {
      flex: 1;
    }
  }
  .title-row {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 16px 0 8px;
  }
}
</style>